<template>
  <div class="container">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/memberShow' }">会员</el-breadcrumb-item>
      <el-breadcrumb-item>{{info.nickName}}</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="flex companyBox">
      <img class="img" :src="info.avatarUrl" />
      <div class="company">
        <div class="title flex">{{info.nickName}}<span>{{info.certification_type === 1 ? '个' : '企'}}</span></div>
        <div class="flex_jcsb_ac classBox">
          <div class="flex class">
            <div class="ification" v-for="(items,indexs) in info.cate_name" :key="indexs">{{items.name}}</div>
          </div>
          <div class="eye">
            <div class="num">{{info.follow || 0}}</div>
            <div class="elView">关注度</div>
          </div>
        </div>
        <div class="connet">企业类型 · {{info.company_type === 1 ? '政府计划/事业单位' : info.company_type === 2 ? '国营' :
          info.company_type === 3 ? '中外合资' : info.company_type === 4 ? '外资' : '其他'}}</div>
      </div>
    </div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane
        :label="item.titel"
        :name="item.titel"
        v-for="item in tabsList"
        :key="item.titel"
      >
        <template #label>
          <div class="flex_ac">
            <span class="tabsTitle">{{item.titel}}</span>
          </div>
        </template>
      </el-tab-pane>
    </el-tabs>
    <div class="businessBox flex" v-if="activeName === '会员信息'">
      <div class="paddingIn">
        <Introduction :complete="'会员展示'" :basicInfo="info" />
      </div>
      <div class="business">
        <div :class="pageHeight ? 'position' : ''">
          <div class="border">
            <div class="title">主营业务</div>
          </div>
          <div class="content">{{info. business || '暂无内容'}}</div>
        </div>
      </div>
    </div>
    <div class="projectBack" v-if="activeName === '会员项目'">
      <ProjectList :paddingAuto="25" :marginRight="22" :list="projectesList" />
      <div class="pagin">
        <Pagination :total="totalCount" :pageSize="12" @changeCurrentPage="changeCurrentPage" v-if="projectesList.length" />
      </div>
    </div>
    <div v-if="activeName === '会员评价'" class="evaluationBox">
      <EvaluationList :list="evaluaList" :countData="countData" />
      <div class="pagin">
        <Pagination :total="evaluCount" :pageSize="8" @changeCurrentPage="changeCurrentPage" v-if="evaluaList.length" />
      </div>
    </div>
  </div>
</template>

<script>
import Introduction from '@/components/userInfo/introduction'
import ProjectList from '@/components/common/projectList'
import EvaluationList from '@/components/evaluation/evaluationList'
import {companyInfo, evaluationList} from '@/api/member/member'
import Pagination from '@/components/common/pagination'
import { ProjectDataList } from '@/api/index/index'
export default {
  name: 'memberInformation',
  components: {
    Introduction,
    ProjectList,
    EvaluationList,
    Pagination
  },
  data() {
    return {
      tabsList: [
        {
          titel: '会员信息',
          quantity: ''
        },
        {
          titel: '会员项目',
          quantity: ''
        },
        {
          titel: '会员评价',
          quantity: ''
        }],
      activeName: '会员信息',
      pageHeight: false,
      totalCount: 0,
      evaluCount: 0,
      page: 1,
      countData: {}, // 评价数量
      projectesList: [], // 项目列表
      evaluaList: [], // 评价列表
      info: {} // 详情
    }
  },
  methods: {
    companyInfoModel() { // 详情
      const data = {
        id: this.$route.query.id
      }
      companyInfo(data).then(res => {
        this.info = res.data
      })
    },
    changeCurrentPage(value) {
      this.page = value
      this.project()
    },
    evaluationModel() {
      const data = {
        page: this.page,
        psize: 12,
        project_uid: this.info.id
      }
      evaluationList(data).then(res => {
        console.log(res.data.count_data)
        this.evaluCount = res.data.count
        this.countData = res.data.count_data
        this.evaluaList = res.data.list
      })
    },
    project() {
      const data = {
        page: this.page,
        psize: 12,
        project_uid: this.info.id
      }
      ProjectDataList(data).then(res => {
        // console.log(res.data)
        this.totalCount = res.data.count
        this.projectesList = res.data.list
      })
    },
    handleClick(e) {
      // console.log('11', e.paneName)
      if (e.paneName === '会员项目') {
        this.project()
      } else if (e.paneName === '会员评价') {
        // console.log('1')
        this.evaluationModel()
      } else {
        this.companyInfoModel()
      }
    },
    handleScroll() { // 页面滑动的距离
      let scrollTop = document.documentElement.scrollTop
      // 当页面滑动的距离大于90px时元素显示，否则不显示
      if (scrollTop >= 330) {
        this.pageHeight = true
      } else {
        this.pageHeight = false
      }
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
    this.companyInfoModel()
  },
  // 组件销毁前
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  }
}
</script>

<style lang="scss" scoped>
  .container {
    width: 1200px;
    margin: 23px auto;
    .pagin{
      margin: 30px auto;
    }
    .evaluationBox{
      background: #fff;
      margin-top: 12px;
      padding: 48px 40px 0 40px;
    }
    .projectBack{
      background: #fff;
      margin-top: 12px;
      padding: 25px;
    }
    .businessBox{
      flex: 1;
      align-items: flex-start;
      margin-top: 12px;
      .paddingIn{
        flex: 1;
        // padding: 0 24px;
      }
      .business{
        width: 240px;
        margin-left: 12px;
        background: #FFF;
        flex-shrink: 0;
        .position{
          position: fixed;
          width: 240px;
          top: 80px;
          z-index: 100;
          background: #FFF;
        }
        .content{
          padding: 24px;
          font-size: 16px;
        }
        .border{
          padding: 24px 36px 17px 36px;
          border-bottom: 1px solid #EEE;
          .title{
            font-size: 18px;
            border-left: 4px solid #1166CF;
            padding-left: 12px;
          }
        }
      }
    }
    /deep/{
      .el-input--prefix .el-input__inner{
        background: #FBFBFB;
        width: 280px;
        height: 36px;
        padding-left: 34px;
      }
      .el-tabs{
        display: flex;
        background: #ECF3FB;
        margin-top: 12px;
        padding: 10px 23px 0 23px;
        justify-content: space-between;
        // border-bottom: 1px solid #DDD;
      }
      .el-tabs__header{
        margin: 0;
      .el-tabs__item{
        height: 50px;
        // width: 96px;
        .tabsIcon{
          width: 24px;
        }
        .quantity{
          background: #1166CF;
          border: 2px solid #FFFFFF;
          border-radius: 8px;
          line-height: 16px;
          min-width: 18px;
          text-align: center;
          padding: 0 5px;
          font-size: 12px;
          color: #fff;
          position: absolute;
          top: 0;
          right: 0;
        }
        .tabsTitle{
          font-size: 18px;
        }
        &.is-active{
          .tabsTitle{
            color: #1166CF;
          }
        }
        &:hover{
          color: #1166CF;
        }
      }
      .el-tabs__active-bar{
        background-color: #1166CF;
        height: 4px;
      }
      .el-tabs__nav-wrap::after{
          height: 0;
        }
      }
    }
    .companyBox{
      padding: 24px 80px 24px 24px;
      background: #FFF;
      margin-top: 20px;
      .company{
        margin-left: 24px;
        flex: 1;
        .connet{
          font-size: 16px;
          color: #666;
        }
        .classBox{
          margin-top: 8px;
          .eye{
            text-align: center;
            .elView{
              color: #666;
              font-size: 16px;
              margin-top: 12px;
            }
            .num{
              font-size: 32px;
            }
          }
          .class{
            flex-wrap: wrap;
            .ification{
              background: #EDF5FF;
              border-radius: 4px;
              padding: 7px 10px;
              color: #1166CF;
              font-size: 14px;
              margin-right: 12px;
            }
          }
        }
        .title{
          font-size: 22px;
          span{
            width: 22px;
            height: 22px;
            background: #FFB400;
            border-radius: 50%;
            margin-left: 9px;
            font-size: 16px;
            line-height: 22px;
            text-align: center;
            color: #fff;
          }
        }
      }
      .img{
        width: 144px;
        height: 144px;
        object-fit: cover;
      }
    }
  }
</style>
